@import '../../common.scss';

.container {
  @include flexCenterStyle;
  height: 64vh;
  background-color: $face-color;

  > p {
    font-size: 3rem;
    color: #aaa;
    font-weight: bold;
    text-align: center;
  }
}
