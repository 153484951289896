@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;
  background-color: $face-color;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .sub {
      font-size: 2rem;
      padding-bottom: 1rem;
      text-align: center;
    }
    > .title {
      @include sectionTitle;
      padding-bottom: 2.8rem;
      text-wrap: balance;
    }
    > .text {
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.8;
      text-wrap: balance;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
    > .linkBtn {
      @include flexCenterStyle;
      @include btn;
      margin-bottom: 5rem;
      color: white;
      background-color: #c3b59b;
      line-height: 1;

      @include mobile {
        padding: 1.625rem 2rem;
        white-space: nowrap;
      }
    }
  }
}
