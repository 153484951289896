@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  flex-direction: column;
  padding: 6rem 0 4rem;
  background-image: url('../../../assets/images/products/tonic/s01/background.jpg');

  > .title {
    @include sectionTitle;
    color: white;
    text-align: center;
    text-wrap: balance;
  }
  > .desc {
    margin-top: 1.6rem;
    font-size: 1.6rem;
    color: white;
    line-height: 1.8;
    text-align: center;
    text-wrap: balance;
  }
  > .product {
    margin-top: 3rem;
  }
}
