@import '../../../common.scss';

.container {
  > .section {
    @include flexCenterStyle;
    padding: 6rem 0;

    > .limiter {
      @include flexCenterStyle;
      @include limiter;
      flex-direction: column;

      > .map {
        width: 100%;
        height: 36.25rem;

        @include mobile {
          height: 30rem;
        }
        > iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
      > .info {
        width: 100%;
        display: flex;
        padding-top: 3rem;

        @include mobile {
          flex-direction: column;
        }
        > div {
          box-sizing: border-box;

          &.address {
            flex: 1;
            padding-right: 4rem;

            @include mobile {
              padding: 0;
              margin-bottom: 4rem;
            }
          }
          &.transport {
            flex: 2;

            .flex {
              display: flex;

              @include tablet {
                flex-direction: column;
              }
              @include mobile {
                flex-direction: column;
              }
              > div {
                margin-bottom: 1rem;

                &.left {
                  flex: 2;
                  padding-right: 2rem;

                  @include tablet {
                    padding-right: 0;
                  }
                  @include mobile {
                    padding-right: 0;
                  }
                }
                &.right {
                  flex: 1;
                }
                > h5 {
                  color: $accent-color;
                  font-size: 1.5rem;
                  border-bottom: 1px solid $accent-color;
                  font-weight: bold;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
          > .title {
            color: $accent-color;
            font-size: 2.6rem;
            font-weight: bold;
            padding-bottom: 2rem;
          }
          > ul > li {
            padding-bottom: 1rem;

            > .title {
              color: $tint-color-1;
              font-size: 1.6rem;
              padding-bottom: 0.5rem;
            }
            p {
              font-size: 1.4rem;
              line-height: 2;

              > span {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
