@import '../../../common.scss';

.container {
  width: 100%;

  > .form {
    @include flexCenterStyle;
    flex-direction: column;

    > .inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include mobile {
        flex-direction: column;
      }
      > ul {
        width: 48%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include mobile {
          width: 100%;
        }
        > li {
          > .title {
            font-size: 1.6rem;
            color: #212121;
            font-weight: bold;
            line-height: 3;
          }
          > .box {
            @include flexCenterStyle;
            margin-bottom: 1rem;

            &.background {
              background-color: #f0ede7;
            }
            > input {
              width: 100%;

              &.text {
                height: 3.75rem;
                background-color: #f0ede7;
                padding: 1.25rem;
                box-sizing: border-box;
                font-size: 1.4rem;
                color: #aaa;
                border: 0;
              }
            }
            > textarea {
              width: 100%;
              border: 0;
              min-height: 21.25rem;
              font-size: 1.4rem;
              color: #aaa;
              padding: 1.25rem;
              box-sizing: border-box;
              background-color: #f0ede7;
            }
          }
        }
      }
    }
  }
}
