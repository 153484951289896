@import '../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  padding: 4rem 0;
  background-color: $face-color;
  text-align: center;

  > p {
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: lighter;

    @include mobile {
      font-size: 1.6rem;
      padding: 0 2rem;
    }
    &.reverse {
      order: 2;
      font-size: 1.6rem;

      @include mobile {
        font-size: 1.4rem;
        padding-top: 1rem;
      }
    }
  }
  > h2 {
    font-size: 3.6rem;
    line-height: 1.5;
    font-weight: bold;
    white-space: nowrap;

    @include mobile {
      font-size: 2.25rem;
      padding: 0 2rem;
    }
  }
}
