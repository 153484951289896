@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .guide {
      margin-top: -2rem;
      font-size: 1.2rem;
      color: gray;
      margin-bottom: 4rem;
      text-align: center;
      text-wrap: balance;
    }
  }
}
