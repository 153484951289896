@import '../../../../common.scss';

.container {
  > .form {
    @include flexCenterStyle;
    flex-direction: column;

    > .inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @include mobile {
        flex-direction: column;
      }
      > ul {
        width: 48%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include mobile {
          width: 100%;
        }
        > li {
          > .title {
            font-size: 1.6rem;
            font-weight: bold;
            line-height: 3;

            &.required {
              &:after {
                content: '*';
                color: $tint-color-1;
                padding-left: 0.5rem;
              }
            }
          }
          > .box {
            @include flexCenterStyle;
            margin-bottom: 1rem;

            &.background {
              background-color: white;
            }
            > input {
              width: 100%;

              &.text {
                height: 3.75rem;
                background-color: white;
                padding: 1.25rem;
                box-sizing: border-box;
                font-size: 1.4rem;
                color: #aaa;
                border: 0;

                &:focus {
                  color: $tint-color-1;
                }
              }
              &.file {
                display: none;
              }
              &.center {
                text-align: center;
              }
            }
            > .connect {
              font-weight: bold;
              padding: 0 1.25rem;
              width: fit-content;
              font-size: 1.4rem;
            }
            > select {
              @include dropDownIcon;
              width: 100%;
              color: #aaa;
              padding: 1.25rem;
              box-sizing: border-box;
              font-size: 1.4rem;
              background-color: white;
              border: 0;
            }
            > textarea {
              width: 100%;
              border: 0;
              min-height: 21.25rem;
              font-size: 1.4rem;
              padding: 1.25rem;
              box-sizing: border-box;

              &:focus {
                color: $tint-color-1;
              }

              @include mobile {
                min-height: 15rem;
              }
            }
            > .btn {
              @include flexCenterStyle;
              font-size: 1.4rem;
              background-color: $tint-color-1;
              color: white;
              border: 0;
              width: fit-content;
              height: 3.75rem;
              white-space: nowrap;
              padding: 0 3rem;
              cursor: pointer;
              margin-left: 1rem;
              line-height: 1;
            }
            > .fileName {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 3.75rem;
              background-color: white;
              padding: 1.25rem;
              box-sizing: border-box;
              font-size: 1.4rem;
              color: #aaa;
              width: 100%;
              text-align: left;
            }
          }
        }
      }
    }
    > .btn {
      @include flexCenterStyle;
      font-size: 1.6rem;
      background-color: $accent-color;
      color: white;
      border: 0;
      white-space: nowrap;
      cursor: pointer;
      min-height: 5rem;
      width: 100%;
      max-width: 24rem;
      margin-top: 4rem;
    }
  }
  > .section {
    @include flexCenterStyle;
    flex-direction: column;
    margin-top: 10rem;
    border-top: 4px solid $tint-color-1;
    background-color: white;
    padding-top: 3rem;

    > .title {
      font-size: 2.6rem;
      color: $tint-color-1;
    }
    > p {
      margin-top: 2rem;
      margin-bottom: 4rem;
      font-size: 1.6rem;
      line-height: 1.8;
      text-align: center;
    }
    > .inner {
      @include flexCenterStyle;
      width: 100%;
      flex-direction: column;
      background-color: rgba(175, 212, 122, 0.2);
      text-align: center;
      padding: 3.75rem;
      box-sizing: border-box;

      > h4 {
        font-size: 2rem;
        line-height: 1.8;
        margin-bottom: 3rem;
      }
      > p {
        font-size: 1.4rem;
        line-height: 1.8;
      }
    }
  }
}
