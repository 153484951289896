@import '../../common.scss';

.container {
  @include flexCenterStyle;
  width: 100%;
  flex-direction: column;

  > .title {
    font-size: 2.6rem;
    margin-bottom: 2rem;
    font-weight: bold;
    text-align: center;
    text-wrap: balance;
  }
  > .products {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    flex-wrap: wrap;

    @media screen and (max-width: 1440px) {
      flex-wrap: wrap;
      max-width: 60rem;
    }
    @include mobile {
      flex-direction: column;
    }
    > li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-right: 1px solid #e5e5e5;
      padding: 0 4rem;
      margin: 2rem 0;
      box-sizing: border-box;

      @media screen and (max-width: 1440px) {
        width: 50% !important;
        margin-bottom: 5rem;
        border: 0;
      }
      @include mobile {
        width: 100% !important;
        margin-bottom: 5rem;
        border: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        border-right: 0;
      }
      > .title {
        font-size: 2rem;
        font-weight: bold;
        padding-bottom: 1rem;
        white-space: nowrap;
        text-align: center;

        > .sub {
          font-size: 1.2rem;
          display: block;
          font-weight: normal;
          margin-bottom: 0.5rem;
        }
      }
      > .image {
        margin: 0.5rem 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        min-height: 12.5rem;
      }
      > .price {
        @include flexCenterStyle;
        width: fit-content;
        color: white;
        padding: 0 1.8rem;
        margin-top: 2rem;
        border-radius: 1.4rem;
        height: 2.8rem;
        font-size: 1.4rem;
        font-weight: bold;

        > .subprice {
          font-size: 60%;
          font-weight: normal;
        }
      }
      > .info {
        margin-top: 1rem;
        font-size: 1.2rem;
        text-align: center;

        > span {
          display: block;
          white-space: nowrap;
        }
      }
    }
  }
}
