@import '../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  padding-bottom: 8rem;
  background-color: $face-color;

  > .limiter {
    @include limiter;
  }
}
