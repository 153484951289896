@import '../../common.scss';

.download {
  @include flexCenterStyle;
  flex-direction: column;

  > .text {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    text-wrap: balance;
  }
  > .buttons {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    @include mobile {
      flex-direction: column;
    }
    > a {
      @include flexCenterStyle;
      width: fit-content;
      padding: 1.2rem 4rem;
      border: 1px solid;
      box-sizing: border-box;
      margin: 1rem;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
    }
  }
}
