@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;
  background-image: url('../../../assets/images/products/breezy/s01/background.jpg');

  @include mobile {
    height: 56rem;
  }
  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
      text-align: center;
      text-wrap: balance;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;

      @include tablet {
        > br {
          display: none;
        }
      }
      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;

    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
    @include mobile {
      width: 100%;
      height: initial;

      &.desktop {
        display: none;
      }
      &.mobile {
        display: block;
      }
    }
  }
}
