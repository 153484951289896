@import '../../common.scss';

.container {
  > .section {
    @include flexCenterStyle;
    width: 100%;
    padding: 6rem 0;

    &:nth-child(2) {
      padding-top: 0;
    }
    &:nth-child(even) {
      background-color: $face-color;
    }
    > .limiter {
      @include limiter;

      > .text {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 4rem;

        @include mobile {
          flex-direction: column;
          text-align: center;
        }
        > h3 {
          white-space: nowrap;
          font-size: 2.6rem;
          font-weight: bold;
          line-height: 1.4;
          color: $accent-color;
          width: 25%;

          @include tablet {
            white-space: initial;
          }
          @include mobile {
            white-space: initial;
            width: 100%;
            padding-bottom: 2rem;
          }
        }
        > p {
          width: 65%;
          font-size: 1.4rem;
          line-height: 1.8;

          @include mobile {
            width: 100%;
          }
        }
      }
      > .image {
        > img {
          width: 100%;
        }
      }
      > .images {
        @include flexCenterStyle;

        > img {
          width: 50%;
        }
      }
    }
  }
}
