@import '../../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #e5f3fe;
  padding: 6rem 2rem;

  > .headline {
    font-size: 2.6rem;
    text-align: center;
    font-weight: bold;
    max-width: 32rem;
  }
  .arrow {
    font-size: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 3rem;
    height: 3rem;
    background: rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .dots {
    bottom: 16px;

    > li {
      margin: 0;
    }
  }
  .title {
    font-size: 2rem;
    color: #005f9a;
    text-align: center;
    font-weight: bold;
    line-height: 1.4;

    &.mineral,
    &.trust {
      max-width: 24rem;
    }
  }
  .figure {
    margin: 2rem auto 0;
    width: 100%;
    max-width: 560px;
  }
  .desc {
    max-width: 640px;
    margin: 2rem auto 0;
    font-size: 1.4rem;
    line-height: 1.6;
    text-align: center;
    padding: 0 2rem;
    text-wrap: balance;

    > .accent {
      color: #00a7f0;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .slider {
    width: 100%;
    max-width: 60rem;
    margin: 3rem auto 0;
    background-color: white;
    border-radius: 1rem;

    .slide {
      width: 100%;
      padding: 3rem 0 4rem;
      box-sizing: border-box;
    }
  }
  > .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    padding: 3rem 0;
    margin: 2rem auto 0;
    background-color: white;
    border-radius: 1rem;
  }
}
