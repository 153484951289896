@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;
  background-color: $face-color;

  @include mobile {
    background-color: white;
  }
  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > div {
      width: 100%;
      border-top: 4px solid;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      padding: 3rem 5rem;
      box-sizing: border-box;

      @include mobile {
        border-top: 0;
        flex-direction: column;
        padding: 0;
        text-align: center;
      }
      &.pulmuone {
        border-top-color: $tint-color-1;
        margin-bottom: 4rem;

        > .text {
          > .title {
            color: $tint-color-1;
          }
        }
      }
      &.family {
        border-top-color: #e62b86;
        margin-bottom: 4rem;

        > img {
          max-width: 12.375rem;
          margin-right: 1rem;
          margin-left: 1rem;
        }
        > .text {
          > .title {
            color: #e62b86;
          }
          > .button {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1rem;
            cursor: pointer;

            @include mobile {
              justify-content: center;
            }
            > .buttonText {
              font-size: 1.4rem;
              color: #e62b86;
              line-height: 1;
            }
          }
        }
      }
      > img {
        width: 100%;
        max-width: 14.375rem;

        @include mobile {
          max-width: 180px;
          margin-bottom: 4rem;
        }
      }
      > .text {
        flex: 1;
        padding-left: 5rem;

        @include mobile {
          padding: 0;
        }
        > .title {
          font-size: 2.2rem;
          padding-bottom: 2rem;

          > span {
            font-weight: bold;
          }
        }
        > p {
          font-size: 1.4rem;
          line-height: 1.8;
          text-wrap: balance;
        }
      }
    }
  }
}
