@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  background-color: $face-color;
  padding-bottom: 6rem;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .overflow {
      width: 100%;
      height: 24rem;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $tint-color-1;
      }
      &::-webkit-scrollbar-track {
        background-color: #ddd;
      }
    }
    > .agree {
      margin-top: 2rem;

      > label {
        @include flexCenterStyle;
        cursor: pointer;

        > .box {
          @include flexCenterStyle;
          width: 2.4rem;
          height: 2.4rem;
          background-color: white;
          color: $tint-color-1;
          font-size: 1.8rem;
          border: 1px solid $tint-color-1;
        }
        > p {
          font-size: 1.4rem;
          padding-left: 1rem;
          line-height: 1;
        }
      }
    }
    > .kind {
      width: 100%;
      margin: 4.75rem 0;

      > .title {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 2;

        &:after {
          content: '*';
          color: $tint-color-1;
          padding-left: 0.5rem;
        }
      }
      > select {
        @include dropDownIcon;
        width: 100%;
        max-width: 672px;
        color: #aaa;
        padding: 1.25rem;
        box-sizing: border-box;
        font-size: 1.4rem;
        border: 0;
        background-color: white;
      }
    }
    > .form {
      width: 100%;

      > .title {
        font-size: 2.4rem;
        font-weight: bold;
        border-bottom: 2px solid #212121;
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}
