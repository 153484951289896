@import '../../common.scss';

.container {
  > .pageTitle {
    @include flexCenterStyle;
    flex-direction: column;
    padding: 4rem;
    background-color: $face-color;
    text-align: center;

    > img {
      width: 100%;
      max-width: 400px;
    }
  }
  > .section {
    @include flexCenterStyle;
    flex-direction: column;
    padding: 6rem 0;

    &.top {
      background-color: $tint-color-2;

      > .limiter {
        @include limiter;
        @include flexCenterStyle;
        flex-direction: column;

        > h3 {
          color: white;
          font-size: 2.6rem;
          line-height: 1.5;
          text-align: center;
          margin-bottom: 3.5rem;

          @include mobile {
            font-size: 2.25rem;

            > br {
              display: none;
            }
          }
        }
        > .center {
          width: 100%;
          max-width: 60rem;
          height: 48rem;
          position: relative;

          @include mobile {
            max-width: 30rem;
          }
          > .desc {
            position: absolute;
            z-index: 3;

            @include mobile {
              text-align: right;
            }
            &.fisrt {
              left: 3%;
              top: 25%;

              > h4 > .number {
                &::after {
                  content: '2';

                  @include mobile {
                    content: '3';
                  }
                }
              }
              @include mobile {
                right: 0;
                top: 69%;
                left: auto;
              }
            }
            &.second {
              top: 2%;
              right: -2rem;

              > h4 > .number {
                &::after {
                  content: '1';
                }
              }
              @include mobile {
                top: 8rem;
                right: 0;
                left: auto;
              }
            }
            &.third {
              right: 0;
              top: 44%;

              > h4 > .number {
                &::after {
                  content: '3';

                  @include mobile {
                    content: '2';
                  }
                }
              }
              @include mobile {
                right: 0;
                top: 42%;
                left: auto;
              }
            }
            > h4 {
              display: flex;
              justify-content: flex-start;
              align-items: flex-end;
              padding-bottom: 1rem;

              @include mobile {
                justify-content: flex-end;
              }
              > span {
                &.number {
                  display: inline-block;
                  width: 2.5rem;
                  height: 2.5rem;
                  font-size: 1.6rem;
                  border-radius: 50%;
                  line-height: 2.5rem;
                  text-align: center;
                  color: $tint-color-2;
                  background-color: white;

                  @include mobile {
                    font-size: 1.5rem;
                    width: 2rem;
                    height: 2rem;
                    line-height: 2rem;
                  }
                }
                &.title {
                  padding-left: 1rem;
                  color: white;
                  font-size: 2rem;

                  @include mobile {
                    padding-left: 0.5rem;
                    font-size: 1.5rem;
                  }
                }
              }
            }
            > p {
              font-size: 1.5rem;
              line-height: 1.6;

              @include mobile {
                font-size: 1.25rem;
              }
            }
          }
          > .bottle {
            @include backgroundDefaultStyle;
            background-image: url('../../assets/images/brand/think-green/bottle.png');
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 30rem;
            height: 100%;

            @include mobile {
              background-image: url('../../assets/images/brand/think-green/bottle_mobile.png');
              background-size: auto 80%;
              background-position: left;
              max-width: 100%;
            }
          }
        }
        > .text {
          width: 100%;
          border-right: 4px solid $accent-color;
          border-left: 4px solid $accent-color;
          background-color: white;
          padding: 5rem 2rem;
          box-sizing: border-box;
          text-align: center;
          margin-top: -5rem;

          @include mobile {
            margin-top: 0rem;
            padding: 2rem;
          }
          > p {
            font-size: 1.4rem;
            line-height: 2;
            padding-bottom: 1rem;

            @include mobile {
              > br {
                display: none;
              }
            }
          }
        }
      }
    }
    &.center {
      > .limiter {
        @include limiter;
        text-align: center;

        > h3 {
          @include sectionTitle;
        }
        > .list {
          margin-top: 4rem;
          display: flex;
          justify-content: space-evenly;

          @include tablet {
            flex-wrap: wrap;
          }
          @include mobile {
            flex-wrap: wrap;
            align-items: flex-start;
          }
          > li {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            @include tablet {
              width: 50%;

              &:first-child {
                width: 100%;
                margin-bottom: 3.5rem;
              }
            }
            @include mobile {
              width: 50%;

              &:first-child {
                width: 100%;
                margin-bottom: 3.5rem;
              }
            }
            > img {
              height: 18.125rem;
              width: auto;
              margin: auto;

              @include mobile {
                height: 9rem;
              }
            }
            > .info {
              margin-top: 1rem;

              > h4 {
                font-size: 1.4rem;
                font-weight: bold;
                padding-bottom: 1rem;
              }
              > p {
                font-size: 1.2rem;
                font-weight: lighter;
              }
            }
          }
        }
      }
    }
    &.bottom {
      background-color: #edf5f9;
      text-align: center;

      > .limiter {
        @include limiter;

        > h3 {
          @include sectionTitle;

          @include mobile {
            > br {
              display: none;
            }
          }
        }
        > img {
          margin-top: 3rem;
          width: 100%;
          max-width: 20rem;

          @include mobile {
            max-width: 400px;
          }
        }
        > p {
          margin-top: 3rem;
          font-size: 1.6rem;
          line-height: 1.8;

          @include mobile {
            > br {
              display: none;
            }
          }
        }
      }
    }
  }
}
