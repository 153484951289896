@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
      text-align: center;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;

      @include tablet {
        > br {
          display: none;
        }
      }
      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;

    @include mobile {
      height: 65%;
    }
  }
  > .objects {
    @include absoluteFill;

    > img {
      position: absolute;
      transform: translate(0, -50%);
      width: 100%;

      &.left {
        left: -12.5rem;
        top: 20%;
        max-width: 25rem;

        @include tablet {
          top: auto;
          bottom: 0;
        }
        @include mobile {
          top: auto;
          bottom: 0;
        }
      }
      &.right {
        right: -30rem;
        top: 50%;
        max-width: 50rem;

        @include mobile {
          top: auto;
          bottom: -30%;
          max-width: 30rem;
          right: -15rem;
        }
      }
      &.bubble_left {
        max-width: 6.25rem;
        left: 20%;
        top: 50%;

        @include mobile {
          display: none;
        }
      }
      &.bubble_right {
        right: 20%;
        top: 60%;
        max-width: 9rem;

        @include mobile {
          display: none;
        }
      }
    }
  }
}
