@import '../../common.scss';

.container {
  position: absolute;
  top: 8rem;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 110;

  @include mobile {
    flex-direction: column;
    justify-content: initial;
  }
  @include tablet {
    flex-direction: column;
    justify-content: initial;
  }
  > .popup {
    margin: 0.5rem;

    > .header {
      display: flex;
      flex-direction: row;
      height: 2.5rem;

      > .left {
        display: flex;
        align-items: center;
        flex: 1;
        background-color: $tint-color-1;
        padding-left: 1rem;
        line-height: 1;
        cursor: pointer;
      }
      > .right {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4rem;
        background-color: $tint-color-2;
        cursor: pointer;
      }
    }
    > .image {
      width: 100%;
      max-width: 40rem;

      &.clickable {
        cursor: pointer;
      }
    }
  }
}
