@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;
  }
}
