@import '../../common.scss';

.container {
  position: relative;
  width: 100%;
  height: 100vh;

  @include mobile {
    height: initial;
  }
  > .wrapper {
    @include absoluteFill;
    display: flex;
    flex-direction: row;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;

    @include mobile {
      position: relative;
      flex-direction: column;
      height: 48rem;
      opacity: 1;
    }
    &:global.on {
      opacity: 1;
    }
    > .left {
      @include backgroundDefaultStyle;
      @include flexCenterStyle;
      flex: 1;

      &.bg01 {
        background-image: url('../../assets/images/home/s02/slide01.jpg');
      }
      &.bg02 {
        background-image: url('../../assets/images/home/s02/slide02.jpg');
      }
      &.bg03 {
        background-image: url('../../assets/images/home/s02/slide03.jpg');
      }
      > img {
        width: 16rem;
        height: 16rem;

        @include mobile {
          width: 12rem;
          height: 12rem;
        }
      }
    }
    > .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      @include mobile {
        align-items: center;
      }
      &.bg01 {
        background-color: #24598e;
      }
      &.bg02 {
        background-color: #44914a;
      }
      &.bg03 {
        background-color: #3487bc;
      }
      > * {
        margin-left: 6rem;

        @include tablet {
          margin-left: 4rem;
        }
        @include mobile {
          margin-left: initial;
        }
      }
      > img {
        width: 6rem;
        height: 6rem;

        @include mobile {
          width: 5rem;
          height: 5rem;
        }
      }
      > h2 {
        margin-top: 1.6rem;
        color: white;
        font-size: 3.2rem;
        font-weight: bold;
        letter-spacing: 1px;

        @include mobile {
          font-size: 2.4rem;
        }
      }
      > p {
        margin-top: 1.6rem;
        font-size: 1.8rem;
        line-height: 1.6;
        color: white;

        @include tablet {
          font-size: 1.6rem;
        }
        @include mobile {
          font-size: 1.4rem;
          text-align: center;
        }
      }
    }
  }
}
