@import '../../common.scss';

.container {
  > .section {
    @include flexCenterStyle;
    padding: 6rem 0 4rem;

    > .limiter {
      @include limiter;
      display: flex;

      > .info {
        width: 50%;

        @include mobile {
          width: 100%;
        }
        > .text {
          border-left: 2px solid #bfd62f;
          padding: 0 2rem;
          margin-bottom: 8rem;

          &:last-child {
            margin-bottom: 0;
          }
          @include mobile {
            border-left: 0;
            text-align: center;
            margin-bottom: 4rem;
            padding: 0;
          }
          > h3 {
            font-size: 2.6rem;
            color: $tint-color-1;
            margin-bottom: 3.6rem;
            font-weight: bold;
            line-height: 1.5;

            @include mobile {
              font-size: 2.25rem;
            }
          }
          > h4 {
            font-size: 2.4rem;
            color: $accent-color;
            margin-bottom: 4rem;
            font-weight: bold;
            line-height: 1.5;

            @include mobile {
              font-size: 2.25rem;
            }
          }
          > p {
            font-size: 1.6rem;
            line-height: 1.8;
            margin-bottom: 4rem;

            &.light {
              font-weight: lighter;
            }
            &:last-child {
              margin-bottom: 0;
            }
            @include mobile {
              margin-bottom: 2rem;
            }
          }
          > img {
            width: 100%;
            display: none;

            @include mobile {
              display: block;
              padding-bottom: 2rem;
            }
          }
        }
      }
      > .illust {
        width: 50%;
        position: relative;

        @include mobile {
          display: none;
        }
        > div {
          @include absoluteFill;
          @include backgroundDefaultStyle;
          background-position: center top;
          background-size: contain;

          &.coloring {
            z-index: 20;
            mask-image: url('../../assets/images/brand/granite/mask.jpg');
            mask-repeat: no-repeat;
            mask-position: center top;
            mask-size: 100% 0;
            background-image: url('../../assets/images/brand/granite/illust-coloring.png');
          }
          &.sketch {
            z-index: 10;
            background-image: url('../../assets/images/brand/granite/illust-sketch.jpg');
          }
        }
      }
    }
  }
}
