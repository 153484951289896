@import '../../../common.scss';

.container {
  > .selectInner {
    @include flexCenterStyle;
    width: 100%;
    background-color: $face-color;
    padding-bottom: 5rem;

    > .select {
      @include flexCenterStyle;
      width: 100%;
      max-width: 53.75rem;

      > select {
        @include dropDownIcon;
        font-size: 1.6rem;
        font-weight: bold;
        background-color: white;
        padding: 1rem;
        color: #aaa;
        box-sizing: border-box;
        min-height: 80px;
        width: 100%;
        max-width: 32rem;

        @include mobile {
          width: 80%;
        }
      }
    }
  }
  > .section {
    @include flexCenterStyle;
    padding: 6rem 0;
    width: 100%;

    > .limiter {
      @include limiter;
      @include flexCenterStyle;
      flex-direction: column;

      > .table {
        width: 100%;

        > .sub {
          color: $accent-color;
          font-size: 2rem;
          padding-bottom: 1rem;
          font-weight: bold;
          text-align: center;
        }
        > .title {
          @include sectionTitle;
          text-align: center;
          margin-bottom: 4.75rem;
        }
        > .date {
          font-weight: lighter;
          text-align: right;
          font-size: 1.4rem;
        }
        > .scrollable {
          overflow: hidden;
          overflow-x: scroll;

          &::-webkit-scrollbar {
            height: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $tint-color-1;
          }
          > .head {
            border-top: 4px solid $accent-color;
            margin-top: 1rem;
            min-width: 780px;

            > ul {
              @include flexCenterStyle;

              > li {
                width: 20%;
                font-size: 1.6rem;
                color: $accent-color;
                padding: 1rem 0;
                font-weight: bold;

                &:not(:first-child) {
                  width: 40%;
                  text-align: center;
                }
              }
            }
          }
          > .body {
            border-bottom: 1px solid $accent-color;
            min-width: 780px;

            > ul {
              > li {
                border-top: 1px solid rgba(6, 84, 44, 0.4);

                > ul {
                  display: flex;

                  > li {
                    width: 100%;
                    padding: 1rem 0;
                    font-size: 1.4rem;
                    border-right: 10px solid white;
                    box-sizing: border-box;
                    background-color: #f4faef;

                    &.point {
                      color: $accent-color;
                      font-weight: bold;
                    }
                    &:first-child {
                      background-color: white;
                    }
                    &:last-child {
                      border-right: 0;
                    }
                    &:not(:first-child) {
                      font-weight: lighter;
                      text-align: right;
                      padding-right: 1rem;
                      box-sizing: border-box;
                    }
                  }
                }
              }
            }
          }
        }
      }
      > .inner {
        width: 100%;
        padding: 2rem 0;

        > .ann {
          display: flex;
          justify-content: space-between;

          > p {
            font-size: 1.4rem;
          }
          > ul {
            > li {
              font-size: 1.4rem;
              padding-bottom: 0.5rem;
              text-align: right;

              &:last-child {
                font-weight: bold;
              }
            }
          }
        }
        > .text {
          border-top: 1px solid $accent-color;
          margin-top: 2rem;

          > p {
            padding-top: 2rem;
            text-align: center;
            font-size: 1.4rem;
            line-height: 1.8;

            @include mobile {
              > br {
                display: none;
              }
            }
            > span {
              display: block;
              font-weight: bold;
              padding-top: 1rem;
            }
          }
        }
      }
    }
  }
}
