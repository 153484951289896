@import '../../common.scss';

.container {
  @include flexCenterStyle;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.4rem;
  background-color: darken($accent-color, 7%);
  z-index: 200;

  @include mobile {
    height: 5.2rem;

    &.background {
      background-color: $accent-color;
    }
  }
  > .wrapper {
    width: 100%;
    max-width: 64rem;
    padding: 0 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    > .hamburger {
      width: 2.5rem;
      height: 1.5rem;
      position: relative;
      cursor: pointer;
      display: none;

      @include mobile {
        display: block;
      }
      &.close {
        > div {
          &.top {
            transform: rotate(45deg);
          }
          &.center {
            transform: rotate(-45deg);
          }
          &.bottom {
            opacity: 0;
          }
        }
      }
      > div {
        width: 100%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: 0;
        border-radius: 1rem;
        transition: all 0.2s ease;

        &.top {
          top: 0;
          transform-origin: 5px 6px;
        }
        &.center {
          top: 50%;
        }
        &.bottom {
          top: 100%;
          width: 60%;
          background-color: $tint-color-1;
        }
      }
    }
    > .logo {
      > a {
        @include backgroundDefaultStyle;
        background-size: contain;
        background-image: url('../../assets/images/shared/header_logo.png');
        display: block;
        width: calc(200 * 2.8rem / 108);
        height: 2.8rem;

        @include mobile {
          background-image: url('../../assets/images/shared/header_logo.png');
          width: 7rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    > .inner {
      width: 100%;

      @include mobile {
        @include absoluteFill;
        position: fixed;
        background-color: $accent-color;
        flex-direction: column;
        justify-content: center;
        top: 66px;
        display: none;

        &.open {
          display: flex;
        }
      }
      > .menus {
        width: 100%;
        display: flex;
        justify-content: space-evenly;

        @include mobile {
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        > li {
          @include flexCenterStyle;
          color: white;
          padding: 0 1rem;
          font-size: 1.2rem;
          height: 4.4rem;
          font-weight: bold;
          box-sizing: border-box;
          line-height: 1;
          cursor: pointer;

          &:first-child {
            margin-left: 4rem;

            @include mobile {
              margin-left: initial;
            }
          }
          @media screen and (min-width: 735px) {
            &:hover {
              color: $tint-color-1;

              > p {
                position: relative;

                &::after {
                  content: '';
                  width: 100%;
                  height: 5px;
                  left: 0;
                  bottom: -25px;
                  background-color: $tint-color-1;
                  position: absolute;
                }
              }
              > .limiter {
                display: flex;
              }
            }
          }
          @include mobile {
            font-size: 1.5rem;
            flex-direction: column;
            height: 100%;
            padding: 1rem;

            &.active {
              > p {
                position: relative;

                &::after {
                  content: '';
                  width: 100%;
                  height: 3px;
                  left: 0;
                  bottom: -10px;
                  background-color: $tint-color-1;
                  position: absolute;
                }
              }
              > .limiter {
                display: flex;
              }
            }
          }
          > .limiter {
            border-top: 1px solid rgba(141, 198, 62, 0.3);
            position: absolute;
            left: 0;
            top: 70px;
            width: 100%;
            justify-content: center;
            align-items: center;
            display: none;
            background-color: $accent-color;

            @include tablet {
              top: 62px;
            }
            @include mobile {
              border-top: 0;
              position: unset;
              flex-direction: column;
              padding-top: 2rem;
            }
            > ul {
              @include flexCenterStyle;
              align-items: flex-end;
              width: 100%;
              max-width: 940px;

              @include mobile {
                flex-direction: column;
              }
              > li {
                text-align: center;
                width: 100%;

                > a {
                  @include flexCenterStyle;
                  font-weight: normal;
                  font-size: 1.2rem;
                  padding: 0 2rem;
                  height: 4.4rem;
                  color: $face-color;
                  white-space: nowrap;

                  @include mobile {
                    font-size: 1.4rem;
                    height: initial;
                    color: $tint-color-1;
                    padding: 1rem;
                  }
                  &:hover {
                    color: $tint-color-1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
