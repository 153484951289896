@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .detail {
      @include flexCenterStyle;
      margin-top: 6rem;
      flex-direction: column;
      width: 100%;
      max-width: 72rem;
      box-sizing: border-box;

      > .title {
        @include sectionTitle;
        text-wrap: balance;
      }
      > .info {
        margin-top: 4rem;
        display: flex;
        justify-content: space-between;
        width: 100%;

        @include tablet {
          flex-direction: column;
        }
        @include mobile {
          flex-direction: column;
        }

        > .image {
          width: 20%;

          @include tablet {
            @include flexCenterStyle;
            width: 100%;
          }
          @include mobile {
            @include flexCenterStyle;
            width: 100%;
            margin-bottom: 4rem;
          }
          > img {
            height: 100%;

            @include tablet {
              height: auto;
              width: 100%;
              max-width: 23rem;
            }
            @include mobile {
              height: auto;
              width: 100%;
              max-width: 23rem;
            }
          }
        }
        > .inner {
          width: 50%;

          @include tablet {
            width: 100%;
          }
          @include mobile {
            width: 100%;
          }
          > ul {
            @include tablet {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
            @include mobile {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
            }
            > li {
              display: flex;
              align-items: center;
              margin-bottom: 1.8rem;

              @include tablet {
                width: 48%;
              }
              @include mobile {
                width: 48%;
                flex-direction: column;
              }

              > img {
                width: 100%;
                max-width: 11.25rem;
                margin-right: 2.125rem;

                @include mobile {
                  max-width: 100%;
                  margin-right: 0;
                }
              }
              > .content {
                > h3 {
                  font-size: 1.8rem;
                  font-weight: bold;
                  padding-bottom: 1rem;

                  @include mobile {
                    padding-top: 1rem;
                  }
                }
                > p {
                  font-size: 1.4rem;
                  line-height: 1.5;
                  text-wrap: balance;

                  @include mobile {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
    > .products {
      margin-top: 6rem;
      width: 100%;
    }
    > .precautions {
      display: flex;
      padding: 2rem 4rem;
      box-sizing: border-box;
      background-color: #f4f4f4;
      border-radius: 1rem;

      @include mobile {
        flex-direction: column;
        padding: 2rem;
        width: 100%;
      }
      > h3 {
        font-size: 1.6rem;
        font-weight: bold;
        white-space: nowrap;

        @include mobile {
          padding-bottom: 2rem;
        }
      }
      > p {
        margin-left: 4rem;
        font-size: 1.4rem;
        line-height: 1.8;

        @include tablet {
          font-size: 1.3rem;
          > br {
            display: none;
          }
        }
        @include mobile {
          font-size: 1.2rem;
          margin-left: 0;
          > br {
            display: none;
          }
        }
      }
    }
  }
}
