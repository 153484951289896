@import '../../common.scss';

.container {
  position: relative;
  height: 100vh;

  @include mobile {
    margin-top: 5.2rem;
    height: 44rem;
  }
  > .slide {
    @include absoluteFill;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &.active {
      opacity: 1;
    }
    > .background {
      @include absoluteFill;
      @include backgroundDefaultStyle;
      z-index: -10;

      @include mobile {
        position: initial;
        width: 100%;
        height: 32rem;
      }
      &.bg01 {
        background-image: url('../../assets/images/home/s01/bg09.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg09-mo.jpg');
        }
      }
      &.bg02 {
        background-image: url('../../assets/images/home/s01/bg01.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg01-mo.jpg');
        }
      }
      &.bg03 {
        background-image: url('../../assets/images/home/s01/bg02.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg02-mo.jpg');
        }
      }
      &.bg04 {
        background-image: url('../../assets/images/home/s01/bg03.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg03-mo.jpg');
        }
      }
      &.bg05 {
        background-image: url('../../assets/images/home/s01/bg06.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg06-mo.jpg');
        }
      }
      &.bg06 {
        background-image: url('../../assets/images/home/s01/bg04.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg04-mo.jpg');
        }
      }
      &.bg07 {
        background-image: url('../../assets/images/home/s01/bg05.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg05-mo.jpg');
        }
      }
      &.bg08 {
        background-image: url('../../assets/images/home/s01/bg08.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg08-mo.jpg');
        }
      }
      &.bg09 {
        background-image: url('../../assets/images/home/s01/bg07.jpg');

        @include mobile {
          background-image: url('../../assets/images/home/s01/bg07-mo.jpg');
        }
      }
    }
    > .wrapper {
      @include limiter;

      @include mobile {
        @include flexCenterStyle;
        flex-direction: column;
        height: 12rem;
        background-color: $accent-color;
        padding-bottom: 2rem;
      }
      // TODO
      &.mobile {
        > h2,
        > h1 {
          display: none;

          @include mobile {
            display: block;
          }
        }
      }
      > h2 {
        color: white;
        font-size: 2.4rem;
        text-shadow: rgba(black, 0.5) 0 0 8px;

        @include mobile {
          font-size: 1.4rem;
        }
      }
      > h1 {
        margin-top: 1.4rem;
        color: white;
        font-size: 4.4rem;
        text-shadow: rgba(black, 0.5) 0 0 8px;
        line-height: 1.2;
        width: 50%;

        @include mobile {
          margin-top: 1rem;
          font-size: 2rem;
          width: initial;
          text-align: center;
          text-wrap: balance;
        }
      }
    }
  }
  > .arrow {
    @include flexCenterStyle;
    position: absolute;
    top: 50%;
    width: 4rem;
    height: 4rem;
    background-color: rgba(black, 0.2);
    box-sizing: border-box;
    cursor: pointer;

    @include mobile {
      top: 14rem;
    }
    &.left {
      left: 0;
      padding-right: 0.5rem;
    }
    &.right {
      right: 0;
      padding-left: 0.5rem;
    }
  }
  > .pagination {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    display: flex;
    flex-direction: row;
    transform: translateX(-50%);

    @include mobile {
      bottom: 1.2rem;
    }
    > .dot {
      width: 0.8rem;
      height: 0.8rem;
      background-color: rgba(black, 0.4);
      margin: 0 0.8rem;
      border-radius: 50%;
      cursor: pointer;

      @include mobile {
        margin: 0 0.5rem;
      }
      &.active {
        background-color: $tint-color-1;
      }
    }
  }
}
