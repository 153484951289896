@import '../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  background-color: #f5f3ef;
  padding-bottom: 6.25rem;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .head {
      width: 100%;
      border-top: 2px solid #05542b;
      border-bottom: 1px solid rgba(65, 54, 40, 0.3);
      display: flex;
      align-items: flex-end;
      margin-bottom: 1.6rem;

      @include mobile {
        flex-direction: column;
      }
      > li {
        width: 50%;
        padding: 2rem 0;

        @include mobile {
          width: 100%;

          &:first-child {
            border-bottom: 1px solid rgba(65, 54, 40, 0.3);
          }
        }
        > p {
          display: flex;
          align-items: flex-end;

          > span {
            &.label {
              font-size: 1.6rem;
              color: #212121;
              font-weight: bold;
              border-left: 4px solid #05542b;
              padding-left: 1rem;
            }
            &.value {
              color: #aaa;
              font-weight: bold;
              font-size: 1.4rem;
              padding-left: 2rem;
            }
          }
        }
      }
    }
    > .btn {
      @include btn;
      background-color: #06542c;
      color: #fff;
      width: 100%;
      max-width: 25rem;
      margin-top: 6.25rem;
    }
  }
}
