@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 1rem;
  background-color: #d8f2eb;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .headline {
      font-size: 2.6rem;
      text-align: center;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    > .desc {
      font-size: 1.4rem;
      max-width: 60rem;
      text-align: center;
      line-height: 1.6;

      &.margin {
        margin-top: 4rem;
      }
    }
    > .features {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;

      @include mobile {
        flex-direction: column;
      }
      > .feature {
        flex: 1;
        margin: 1rem;

        > .imageWrapper {
          position: relative;

          > img {
            width: 100%;
            vertical-align: bottom;
          }
          > .title {
            @include absoluteFill;
            color: white;
            font-size: 1.4rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        > .bottom {
          background-color: white;
          font-size: 1.4rem;
          padding: 1rem 3rem;
          line-height: 1.6;
          min-height: 8rem;
          box-sizing: border-box;
          display: flex;
          text-align: center;
          align-items: center;
          text-wrap: balance;

          @include tablet {
            font-size: 1.2rem;
          }
        }
      }
    }
    > .box {
      margin-top: 3rem;
      background-color: white;
      width: 100%;
      max-width: 64rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2rem 2rem 4rem 0;
      box-sizing: border-box;

      @include mobile {
        flex-direction: column;
        padding: 2rem 2rem 3rem;
      }
      > img {
        margin: -4rem 0 -10rem;
        width: 36%;
        max-width: 30rem;

        @include mobile {
          width: 100%;
          margin: 0 0 2rem;
        }
      }
      > .wrapper {
        margin-left: 2rem;

        @include mobile {
          margin: 0;
        }
        > .sub {
          font-size: 1.6rem;
        }
        > .title {
          font-size: 2.8rem;
          font-weight: bold;
          line-height: 1.4;
        }
        > ul {
          margin-top: 2rem;

          > li {
            display: flex;
            flex-direction: row;
            margin-top: 1rem;

            > .number {
              width: 2rem;
              height: 2rem;
              background-color: #1fa7a7;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 1.2rem;
              border-radius: 4px;
            }
            > .content {
              flex: 1;
              margin-left: 0.5rem;
              font-size: 1.4rem;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
}
