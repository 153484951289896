@import '../../common.scss';

.carousel {
  width: 100%;
  line-height: 0;

  .item {
    display: flex;
    flex-direction: column;

    > .image {
      @include backgroundDefaultStyle;
      width: 100%;
      position: relative;
      height: 14rem;

      > .number {
        @include flexCenterStyle;
        position: absolute;
        width: 2.5rem;
        height: 2.5rem;
        color: white;
        background-color: $accent-color;
        font-size: 1.2rem;
      }
      > img {
        width: 100%;
      }
    }
    > .text {
      @include flexCenterStyle;
      padding: 1.5rem;
      box-sizing: border-box;
      background-color: white;
      font-size: 1.4rem;
      line-height: 1;
      height: 4rem;
    }
  }
  > .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .pager {
      display: flex;
      align-items: center;

      > span {
        display: block;
        width: 12px;
        height: 12px;
        border-right: 50%;
        background-color: $accent-color;
        opacity: 0.3;

        &.bulletsActive {
          opacity: 1;
        }
      }
    }
    > .nav {
      @include flexCenterStyle;

      > button {
        @include flexCenterStyle;
        border: 0;
        width: 3rem;
        height: 3rem;
        background-color: $accent-color;
        cursor: pointer;

        > img {
          width: 100%;
          max-width: 0.8rem;
        }
      }
    }
  }
}
