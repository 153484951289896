@import '../../common.scss';

.container {
  text-align: center;
  margin-bottom: 3.5rem;
  width: 100%;
  max-width: 80rem;

  > .title {
    font-size: 1.6rem;
    font-weight: bold;
    padding-bottom: 1rem;
    text-align: center;
  }
  > .table {
    margin-top: 0.5rem;
    overflow: hidden;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      height: 8px;
    }
    .column {
      @include flexCenterStyle;
      border-right: 2px solid white;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1;
      padding: 1rem 0;
      width: 100%;
      min-width: 8rem;
      box-sizing: border-box;

      &:last-child {
        border-right: 0;
      }
    }
    > .head {
      display: flex;
      flex-direction: row;
      border-bottom: 2px solid white;
    }
    > .body {
      > .row {
        &:not(:last-child) {
          border-bottom: 2px solid white;
        }
        > ul {
          display: flex;
          flex-direction: row;

          > li {
            background-color: #f4f4f4;
            font-weight: normal;
          }
        }
      }
    }
    .leading {
      min-width: 12rem;
    }
  }
}
