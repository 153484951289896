@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
      text-align: center;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;
      text-wrap: balance;

      @include tablet {
        > br {
          display: none;
        }
      }
      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
}
