@import '../../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  > .headline {
    font-size: 2.6rem;
    text-align: center;
    font-weight: bold;
    max-width: 32rem;
  }
  > .columns {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 62rem;

    &.places {
      margin-top: 6rem;
    }
    > .column {
      width: 33.3%;
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      box-sizing: border-box;

      @include mobile {
        width: 100%;
      }
      > img {
        width: 100%;
        align-self: center;
        border-radius: 1rem;
      }
      > .title {
        margin-top: 1.6rem;
        border-left: 6px solid #64aadd;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1;
        padding: 4px 0 4px 12px;
      }
      > .desc {
        margin-top: 0.5rem;
        font-size: 1rem;
      }
    }
  }
  > .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 62rem;
    padding: 0 1.5rem;
    box-sizing: border-box;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
    > .left {
      width: 16rem;
      border-left: 6px solid #64aadd;
      padding: 0 0 4px 12px;
      box-sizing: border-box;

      > .title1 {
        font-size: 1.4rem;
        font-weight: bold;
      }
      > .title2 {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }
    > .right {
      flex: 1;
      border-left: 1px solid lightgray;
      padding: 0 0 8px 2rem;
      font-size: 1.1rem;

      @include mobile {
        margin-top: 0.5rem;
        font-size: 1rem;
        padding: 0;
        border-left: none;
      }
    }
  }
  > .box {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
    max-width: 52rem;
    border-radius: 1rem;
    background-color: #f0f0f0;
    font-size: 0.8rem;

    > small {
      font-size: 60%;
    }
  }
}
