@import '../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  flex-direction: column;
  width: 100%;
  height: 20rem;
  padding: 2rem 0;

  @include mobile {
    box-sizing: border-box;
    padding: 7rem 0 2rem 0;
  }
  > .title {
    color: white;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
  }
  > .subtitle {
    margin-top: 1rem;
    color: white;
    font-size: 1.8rem;
    font-weight: lighter;
  }
}
