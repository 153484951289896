@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  flex-direction: column;
  padding: 6rem 2rem 8rem;
  background-image: url('../../../assets/images/products/tonic/s02/background.jpg');

  > .headline {
    font-size: 2.6rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  > .wrapper {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    width: 100%;
    max-width: 72rem;

    @include mobile {
      flex-direction: column;
    }
    > .box {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border-radius: 1rem;
      padding: 4rem 0;
      box-sizing: border-box;
      gap: 1rem;

      > img {
        width: 7rem;
      }
      > .title {
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        line-height: 1.6;

        > strong {
          color: #4a8b12;
        }
      }
    }
  }
}
