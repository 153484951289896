@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;
  background-color: #edf5f9;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .title {
      font-size: 2.6rem;
      text-align: center;
      text-wrap: balance;
    }
    > .row {
      width: 100%;
      border-radius: 1rem;
      background-color: white;
      padding: 2rem;
      box-sizing: border-box;
      margin: 3.75rem 0;

      @include mobile {
        padding: 1rem;
      }
      > ul {
        @include flexCenterStyle;
        flex-wrap: wrap;

        @include mobile {
          margin-top: 2rem;
        }
        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          width: 12.5%;
          position: relative;

          @include tablet {
            width: 33.3334%;
            margin-bottom: 4rem;

            &:nth-child(3n) {
              &::after {
                display: none;
              }
            }
          }
          @include mobile {
            width: 32%;
            margin-bottom: 2rem;

            &:nth-child(3n) {
              &::after {
                display: none;
              }
            }
          }
          &::after {
            content: '';
            position: absolute;
            transform: translate(-50%, -50%);
            right: -16px;
            bottom: 0;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left-color: #00a8da;

            @include mobile {
              right: calc(-1% - 16px);
              bottom: -3px;
            }
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          > .time {
            font-weight: bold;
            font-size: 1.4rem;
            color: #00a8da;
            opacity: 0.3;
            padding-bottom: 1rem;

            @include tablet {
              display: none;
            }
            @include mobile {
              display: none;
            }
          }
          > img {
            width: auto;
            height: 100%;

            @include mobile {
              width: 70%;
              height: auto;
            }
          }
          > .text {
            font-size: 1.2rem;
            padding-top: 1rem;
          }
        }
      }
    }
    > .text {
      font-size: 1.6rem;
      line-height: 1.8;
      text-align: center;
      padding-bottom: 0.5rem;
      text-wrap: balance;

      @include mobile {
        > br {
          display: none;
        }
      }
      > .accent {
        display: block;
        font-size: 2.2rem;
        color: $tint-color-4;
        font-weight: bold;
        padding-top: 2rem;
      }
    }
    > .from {
      margin-top: 4rem;
      text-align: center;
      font-weight: lighter;
      font-size: 1.2rem;
      line-height: 1.8;
    }
  }
}
