@import '../../common.scss';

.carousel {
  line-height: 0;
  position: relative;

  img {
    width: 100%;
  }
  > .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .pager {
      display: flex;
      align-items: center;

      @include mobile {
        padding: 1rem 0;
        justify-content: center;
      }
      > span {
        display: block;
        width: 12px;
        height: 12px;
        border-right: 50%;
        background-color: $accent-color;
        opacity: 0.3;

        &.bulletsActive {
          opacity: 1;
        }
      }
    }
    > .nav {
      @include flexCenterStyle;

      > button {
        @include flexCenterStyle;
        border: 0;
        width: 3rem;
        height: 3rem;
        background-color: $accent-color;
        cursor: pointer;

        @include mobile {
          z-index: 2;
          position: absolute;
          top: 40%;
        }
        &.prev {
          @include mobile {
            left: 0;
          }
        }
        &.next {
          @include mobile {
            right: 0;
          }
        }
        > img {
          width: 100%;
          max-width: 0.8rem;
        }
      }
    }
  }
}
