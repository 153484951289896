@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;
  background-color: #f0f8e9;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .title {
      @include sectionTitle;
      padding-bottom: 4rem;
      text-wrap: balance;
    }
    > .list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4rem;

      @include tablet {
        flex-direction: column;
        width: 100%;
      }
      @include mobile {
        flex-direction: column;
        width: 100%;
      }
      > li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 49%;
        background-color: white;
        padding: 4.875rem 8.125rem;
        box-sizing: border-box;

        @include tablet {
          width: 100%;
          padding: 3rem 2rem;

          &:first-child {
            margin-bottom: 2rem;
          }
        }
        @include mobile {
          width: 100%;
          padding: 3rem 2rem;

          &:first-child {
            margin-bottom: 2rem;
          }
        }
        > img {
          height: 7.5rem;
          width: auto;
        }
        > p {
          font-size: 1.8rem;
          font-weight: bolder;
          line-height: 1.5;
          text-align: center;
          margin-top: 2rem;
          text-wrap: balance;

          > .point {
            color: $accent-color;
          }
          > .sub {
            display: block;
            font-size: 1.4rem;
            padding-top: 1rem;
          }
        }
      }
    }
    > .inner {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile {
        flex-direction: column;
      }
      > .image {
        @include flexCenterStyle;
        flex-direction: column;
        background-color: #dfebd0;
        border-radius: 1rem;
        width: 100%;
        max-width: 16rem;
        padding: 0.5rem;
        box-sizing: border-box;

        > .box {
          @include flexCenterStyle;
          width: 100%;
          border-radius: 1rem;
          background-color: white;
          padding: 2rem;
          box-sizing: border-box;

          > img {
            width: 100%;
            max-width: 6rem;
          }
        }
        > .name {
          font-size: 1.6rem;
          font-weight: bold;
          color: $accent-color;
          padding: 1rem 0;
          text-align: center;
        }
      }
      > .text {
        font-size: 1.4rem;
        line-height: 1.8;
        padding-left: 2rem;

        @include mobile {
          padding-left: 0;
          margin-top: 2rem;
        }
      }
    }
  }
}
