@import '../../common.scss';

.container {
  > .section {
    @include flexCenterStyle;
    flex-direction: column;
    padding: 6rem 0;
    text-align: center;

    &.top {
      background-color: #bed734;

      > .limiter {
        @include limiter;

        > h3 {
          color: white;
          font-size: 2.6rem;
          font-weight: bold;
        }
        > p {
          margin-top: 2rem;
          font-size: 1.6rem;
          color: white;
          line-height: 1.8;
        }
        > h4 {
          margin-top: 2rem;
          color: white;
          font-weight: bold;
          font-size: 2.2rem;
          line-height: 2;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            width: calc(50% - 10rem);
            height: 1px;
            background-color: white;
          }
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            width: calc(50% - 10rem);
            height: 1px;
            background-color: white;
          }
        }
        > .list {
          margin-top: 2rem;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          > li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            width: 18%;
            padding: 2.4rem 0;
            border-radius: 1rem;
            margin: 0.5rem;

            @include tablet {
              width: 30%;
            }
            @include mobile {
              width: 45%;
            }
            > img {
              width: auto;
              height: 6rem;
            }
            > p {
              font-size: 1.2rem;
              font-weight: bold;
              line-height: 1.5;
              padding-top: 2rem;
            }
          }
        }
      }
    }
    &.bottom {
      background-color: $face-color;

      > .limiter {
        @include limiter;

        > h3 {
          @include sectionTitle;
        }
        > p {
          margin-top: 1rem;
          font-size: 1.6rem;
        }
        > .list {
          margin-top: 3rem;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;

          > li {
            @include flexCenterStyle;
            flex-direction: column;
            border-radius: 1rem;
            background-image: linear-gradient(to bottom, $tint-color-1 0% 30%, white 30% 70%);
            padding: 2rem;
            box-sizing: border-box;
            width: 22%;
            margin: 0.5rem;

            @include tablet {
              width: 45%;
            }
            @include mobile {
              width: 45%;
            }
            &:last-child {
              margin-right: 0;
            }
            > h4 {
              color: white;
              font-size: 1.8rem;
              font-weight: bold;
              padding-bottom: 1rem;
            }
            > img {
              width: 80%;
              max-width: 14rem;
              height: auto;
            }
            > p {
              font-size: 1.2rem;
              font-weight: lighter;
              padding: 0.5rem 0;

              > .point {
                display: block;
                font-weight: bold;
                color: $accent-color;
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }
}
