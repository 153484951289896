@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  background-color: #002359;
  padding: 6rem 2rem;

  > .headline {
    font-size: 2.6rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: 300;

    > .accent {
      font-weight: bold;
    }
    &.feature {
      margin-top: 4rem;
    }
  }
  > .desc {
    color: white;
    font-size: 1.4rem;
    max-width: 60rem;
    text-align: center;
    line-height: 1.6;
    text-wrap: balance;
  }
  > .figure1 {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
    max-width: 780px;
  }
  > .figure2 {
    margin-top: 2rem;
    width: 100%;
    max-width: 570px;
  }
}
