@import '../../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  > .headline {
    font-size: 2.6rem;
    text-align: center;
    font-weight: bold;
    max-width: 32rem;
  }
  > .columns {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 48rem;
    gap: 4rem;

    @include mobile {
      flex-direction: column;
    }
    > .column {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      @include mobile {
        width: 100%;
        max-width: 24rem;
      }
      > .color {
        margin-top: 2rem;
        display: flex;
        flex-direction: row;
        align-self: center;
        align-items: center;
        gap: 1rem;

        > .circle {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          border: 1px solid lightgray;

          &.white {
            background-color: #e8e9e4;
          }
          &.black {
            background-color: #1a1a1a;
          }
        }
        > .name {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
      > img {
        margin-top: 2rem;
        width: 15rem;
        align-self: center;
      }
    }
  }
}
