@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 1rem;
  background-color: #f1f8c0;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .headline {
      font-size: 2.6rem;
      text-align: center;
      font-weight: bold;
      text-wrap: balance;

      > strong {
        color: #4a8b12;
      }
    }
    > .tip {
      margin-top: 4rem;
      display: flex;
      align-items: center;
      height: 2rem;
      padding: 0 1.6rem;
      color: white;
      font-size: 1.2rem;
      font-weight: bold;
      border-radius: 4px;
      background-color: #4a8b12;
    }
    > .desc {
      margin-top: 1rem;
      font-size: 1.4rem;
      max-width: 60rem;
      text-align: center;
      line-height: 1.6;
      text-wrap: balance;
    }
    > .wrapper {
      margin-top: 3rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.2rem;
      width: 100%;
      max-width: 72rem;

      @include mobile {
        flex-direction: column;
      }
      > .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border-radius: 1rem;
        padding: 2rem 1rem 1rem;
        box-sizing: border-box;
        width: 30%;
        gap: 1rem;

        @include mobile {
          width: 100%;
        }
        > div > h3 {
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.4;
          text-align: center;
        }
        > img {
          width: 12rem;
        }
        > .bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f4f4f4;
          width: 100%;
          height: 5rem;
          border-radius: 0.8rem;
          line-height: 1.4;

          > .text {
            font-size: 1.2rem;
            text-align: center;

            > strong {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
