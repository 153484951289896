@import '../../../common.scss';

.container {
  width: 100%;

  > .head {
    border-top: 2px solid #05542b;
    border-bottom: 1px solid rgba(65, 54, 40, 0.3);
    display: flex;
    align-items: flex-end;

    > li {
      padding: 1rem;
      box-sizing: border-box;
      color: $accent-color;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }
  > .body > li {
    border-bottom: 1px solid rgba(65, 54, 40, 0.3);
    font-size: 1.4rem;
    font-weight: bold;

    > a > ul {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      > li {
        padding: 1rem;
        box-sizing: border-box;
      }
    }
  }
  .column {
    &:nth-child(1) {
      width: 10%;
      text-align: center;

      @include mobile {
        width: 5%;
      }
    }
    &:nth-child(2) {
      width: 70%;

      @include mobile {
        width: 65%;
        text-align: center;
      }
    }
    &:nth-child(3) {
      width: 20%;
      text-align: center;

      @include mobile {
        width: 30%;
      }
    }
  }
}
