@import '../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  padding: 6rem 0;
  background-image: url('../../assets/images/home/s04/background.png');

  @include mobile {
    padding: 5rem 0;
  }
  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;
    text-align: center;

    > .title {
      color: white;
      font-size: 2.6rem;
      line-height: 1.5;

      @include mobile {
        font-size: 2rem;
      }
    }
    > .buttons {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 52rem;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }
      > .button {
        @include flexCenterStyle;
        flex-direction: column;
        background-color: white;
        width: 100%;
        max-width: 24rem;
        cursor: pointer;

        @include mobile {
          width: 80%;
        }
        > a {
          width: 100%;
          display: block;
          padding: 2.4rem 0;
          transition: all 0.2s ease;

          @include mobile {
            padding: 2rem 0;
          }
          > .icon {
            @include backgroundDefaultStyle;
            background-size: contain;
            width: 3rem;
            height: 3rem;
            margin: auto;
            transition: all 0.2s ease;
            background-image: url('../../assets/images/shared/outro/contact_icon_green.svg');
          }
          > .label {
            padding-top: 0.5rem;
            font-weight: bold;
            font-size: 1.6rem;
            transition: all 0.2s ease;
            color: $accent-color;

            @include mobile {
              padding-top: 0;
            }
          }
          &:hover {
            background-color: $tint-color-1;

            > .icon {
              background-image: url('../../assets/images/shared/outro/contact_icon_white.svg');
            }
            > .label {
              color: white;
            }
          }
        }
      }
    }
  }
}
