@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  @include mobile {
    background-position: -24rem center;
  }
  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
      text-align: center;
    }
    > .text {
      padding-top: 1rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
  > .objects {
    @include absoluteFill;

    > img {
      position: absolute;
      transform: translate(0, -50%);
      width: 100%;

      &.bubbleLeft {
        max-width: 11.25rem;
        top: 60%;
        left: 35%;

        @include mobile {
          left: 0;
          max-width: 8rem;
          top: 80%;
        }
      }
      &.bubbleRight {
        max-width: 6.25rem;
        top: 50%;
        right: 35%;

        @include mobile {
          right: 0;
          top: 70%;
          max-width: 5rem;
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @include tablet {
      height: 90%;
    }
    @include mobile {
      height: 80%;
    }
  }
}
.inner {
  @include flexCenterStyle;
  flex-direction: column;
  background-color: $tint-color-4;
  padding: 5rem 0;

  @include tablet {
    padding: 3rem 2rem;
  }
  @include mobile {
    padding: 3rem 2rem;
  }
  > p {
    padding-bottom: 0.5rem;
    text-align: center;
    color: white;
    font-size: 1.6rem;
    line-height: 1.8;

    @include tablet {
      > br {
        display: none;
      }
    }
    @include mobile {
      > br {
        display: none;
      }
    }
  }
}
