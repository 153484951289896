@import '../../common.scss';

.container {
  background-color: white;
  padding: 4rem;

  @include tablet {
    padding: 3rem;
  }
  @include mobile {
    padding: 2rem;
  }
  > h3 {
    font-size: 1.6rem;
    font-weight: bold;
    margin-top: 3rem;
    line-height: 2;

    &:first-child {
      margin-top: 0;
    }
  }
  > ul > li {
    font-size: 1.4rem;
    line-height: 1.8;
    font-weight: lighter;
    word-break: break-all;
  }
  > p {
    font-size: 1.4rem;
    line-height: 1.8;
    font-weight: lighter;
  }
  span {
    display: block;
    margin-left: 1rem;
  }
  > .scrollable {
    overflow: hidden;
    overflow-x: scroll;

    table {
      margin-top: 1rem;
      min-width: 600px;
      width: 100%;
      border-top: 2px solid black;

      > thead {
        background-color: #eee;
        font-weight: bold;
      }
      td {
        font-size: 1.2rem;
        font-weight: lighter;
        border-bottom: 1px solid gray;
        border-right: 1px solid gray;
        padding: 0.5rem 1rem;
        text-align: center;

        &:first-child {
          border-left: 1px solid gray;
        }
      }
    }
  }
  .popup {
    color: $tint-color-1;
    cursor: pointer;
    text-decoration: underline;
  }
}
.branches {
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 24rem;
  height: 100%;
  max-height: 24rem;
  border: 4px solid $tint-color-1;

  > .close {
    @include flexCenterStyle;
    position: absolute;
    right: 8px;
    top: 0;
    width: 3rem;
    height: 2rem;
    font-size: 1rem;
    color: white;
    cursor: pointer;
    background-color: $tint-color-1;
    z-index: 10;
  }
  > ul {
    @include absoluteFill;
    list-style: decimal;
    overflow: hidden;
    overflow-y: scroll;
    padding: 1rem 1rem 1rem 3rem;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $tint-color-1;
    }
    &::-webkit-scrollbar-track {
      background-color: #ddd;
    }
    > li {
      font-size: 1rem;
      color: #212121;
      line-height: 1.4;
    }
  }
}
