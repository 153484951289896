@import '../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  padding: 6rem 0;

  &.green {
    background-image: url('../../assets/images/shared/outro/background_green.jpg');
  }
  &.lightgreen {
    background-image: url('../../assets/images/shared/outro/background_lightgreen.jpg');
  }
  &.blue {
    background-image: url('../../assets/images/shared/outro/background_blue.jpg');
  }
  &.emerald {
    background-image: url('../../assets/images/shared/outro/background_emerald.jpg');
  }
  &.pure {
    background-image: url('../../assets/images/shared/outro/background_pure.jpg');
  }
  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;
    text-align: center;

    > .title {
      color: white;
      font-size: 2.6rem;

      @include mobile {
        font-size: 2rem;
      }
      > span {
        display: block;
        line-height: 1.5;
      }
    }
    > .buttons {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 52rem;

      @include mobile {
        flex-direction: column;
        align-items: center;
      }
      > .button {
        @include flexCenterStyle;
        flex-direction: column;
        background-color: white;
        width: 100%;
        max-width: 24rem;
        cursor: pointer;

        @include mobile {
          width: 80%;
        }
        &:not(:first-child) {
          margin-left: 1rem;

          @include mobile {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
        > a {
          width: 100%;
          display: block;
          padding: 2.4rem 0;
          transition: all 0.2s ease;

          @include mobile {
            padding: 2rem 0;
          }
          > .icon {
            @include backgroundDefaultStyle;
            background-size: contain;
            width: 3rem;
            height: 3rem;
            margin: auto;
            transition: all 0.2s ease;

            &.contact {
              &.green {
                background-image: url('../../assets/images/shared/outro/contact_icon_green.svg');
              }
              &.lightgreen {
                background-image: url('../../assets/images/shared/outro/contact_icon_lightgreen.svg');
              }
              &.blue {
                background-image: url('../../assets/images/shared/outro/contact_icon_blue.svg');
              }
              &.emerald {
                background-image: url('../../assets/images/shared/outro/contact_icon_emerald.svg');
              }
              &.pure {
                background-image: url('../../assets/images/shared/outro/contact_icon_pure.svg');
              }
            }
            &.order {
              &.green {
                background-image: url('../../assets/images/shared/outro/order_icon_green.svg');
              }
              &.lightgreen {
                background-image: url('../../assets/images/shared/outro/order_icon_lightgreen.svg');
              }
              &.blue {
                background-image: url('../../assets/images/shared/outro/order_icon_blue.svg');
              }
              &.emerald {
                background-image: url('../../assets/images/shared/outro/order_icon_emerald.svg');
              }
              &.pure {
                background-image: url('../../assets/images/shared/outro/order_icon_pure.svg');
              }
            }
            &.web {
              &.pure {
                background-image: url('../../assets/images/shared/outro/web_icon_pure.svg');
              }
            }
          }
          > .label {
            padding-top: 0.5rem;
            font-weight: bold;
            font-size: 1.6rem;
            transition: all 0.2s ease;

            @include mobile {
              padding-top: 0;
            }
            &.green {
              color: $accent-color;
            }
            &.lightgreen {
              color: #209f3d;
            }
            &.blue {
              color: $tint-color-4;
            }
            &.emerald {
              color: #0f7e7e;
            }
            &.pure {
              color: #0063c1;
            }
          }
          &:hover {
            &.green {
              background-color: $tint-color-1;
            }
            &.lightgreen {
              background-color: $tint-color-1;
            }
            &.blue {
              background-color: #64bae8;
            }
            &.emerald {
              background-color: #41b9c4;
            }
            &.pure {
              background-color: #3ea6f6;
            }
            > .icon {
              &.contact {
                background-image: url('../../assets/images/shared/outro/contact_icon_white.svg');
              }
              &.order {
                background-image: url('../../assets/images/shared/outro/order_icon_white.svg');
              }
              &.web {
                background-image: url('../../assets/images/shared/outro/web_icon_white.svg');
              }
            }
            > .label {
              color: white;
            }
          }
        }
      }
    }
  }
}
