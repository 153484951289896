@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  background-image: url('../../../assets/images/company/pulmuone/s01/background.jpg');
  box-sizing: border-box;
  height: 61.62rem;
  overflow: hidden;
  position: relative;

  @include mobile {
    background-color: $accent-color;
    background-image: none;
    height: auto;
  }
  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;
    position: relative;

    > img {
      position: absolute;
      width: 100%;

      &.left {
        left: 0;
        top: 0;
        max-width: 7.5rem;

        @include mobile {
          top: auto;
          bottom: 18rem;
          left: 1rem;
        }
      }
      &.right {
        right: 0;
        top: 20%;
        max-width: 12.5rem;

        @include mobile {
          top: auto;
          bottom: 10rem;
          right: 1rem;
          max-width: 10rem;
        }
      }
    }
    > .box {
      width: 100%;
      max-width: 72rem;
      background-color: rgba($accent-color, 0.8);

      > .grid {
        @include flexCenterStyle;
        margin: 3rem;
        padding: 4rem 2rem;
        background-color: white;

        @include mobile {
          flex-direction: column;
          padding: 2rem;
        }
        > .ci {
          width: 48%;

          @include mobile {
            width: 64%;
          }
        }
        > .text {
          margin-left: 2rem;
          box-sizing: border-box;

          @include mobile {
            width: 100%;
            margin-top: 4rem;
            margin-left: 0;
            margin-bottom: 4rem;
            text-align: center;
          }
          > h3 {
            font-size: 2rem;
            line-height: 1.5;
          }
          > p {
            margin-top: 2.4rem;
            font-size: 1.4rem;
            font-weight: lighter;
            line-height: 1.8;

            @include tablet {
              > br {
                display: none;
              }
            }
            @include mobile {
              > br {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
