@import '../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  flex-direction: column;
  background-color: #f5f3ef;
  width: 100%;
  padding: 6rem 0;

  @include mobile {
    padding: 5rem 0;
  }
  > h1 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  > h2 {
    margin-top: 1rem;
    font-size: 3rem;
    font-weight: bold;
  }
  > ul {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 72rem;

    @include tablet {
      width: 36rem;
    }
    @include mobile {
      width: 24rem;
    }
    li {
      width: 12rem;
      height: 12rem;
      background-size: cover;
      cursor: pointer;
    }
  }
  > a {
    display: flex;
    justify-content: center;
    line-height: 1;
    font-size: 1.6rem;
    width: 14rem;
    margin-top: 3rem;
    padding: 1.2rem 1.8rem;
    color: $accent-color;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: $accent-color;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: white;
      border-color: transparent;
      background-color: $tint-color-1;
    }
  }
}
