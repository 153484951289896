@import '../../common.scss';

.container {
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 100;

  @include mobile {
    display: none;
  }
  > ul > li {
    @include flexCenterStyle;
    flex-direction: column;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);

    &.shop {
      background-color: $tint-color-1;

      > .icon {
        background-image: url('../../assets/images/shared/shop_icon.svg');
        background-position: left;
        width: 2rem;
      }
    }
    &.top {
      margin-top: 1.5rem;
      background-color: $accent-color;

      > .icon {
        background-image: url('../../assets/images/shared/top_icon.svg');
      }
    }
    > span {
      color: white;
      font-size: 1;

      &.icon {
        @include backgroundDefaultStyle;
        background-size: contain;
        width: 1.5rem;
        height: 1.5rem;
      }
      &.title {
        margin-top: 0.5rem;
      }
    }
  }
}
