@import '../../../common.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 2rem;

  > .headline {
    font-size: 2.6rem;
    text-align: center;
    font-weight: bold;
    max-width: 32rem;
  }
  > img {
    margin-top: 3rem;
    width: 100%;
    max-width: 32rem;
    border-radius: 2rem;
  }
  > .columns {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 36rem;

    @include mobile {
      flex-wrap: wrap;
    }
    > .column {
      margin-top: 1rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      @include mobile {
        flex: initial;
        width: 50%;
      }
      > img {
        width: 5rem;
        height: 5rem;
      }
      > .title {
        text-align: center;
        font-size: 1.2rem;
      }
    }
  }
  > .box {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    background-color: #eeeef0;
    padding: 1.5rem 2rem 1.8rem;
    border-radius: 4px;
    font-size: 1.2rem;
    text-align: center;
    text-wrap: balance;
  }
}
