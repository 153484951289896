@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;

    > .products {
      margin-top: 6rem;
    }
    > .precautions {
      display: flex;
      padding: 2rem 4rem;
      box-sizing: border-box;
      background-color: #f4f4f4;
      border-radius: 1rem;

      @include mobile {
        flex-direction: column;
        padding: 2rem;
        width: 100%;
      }
      > h3 {
        font-size: 1.6rem;
        font-weight: bold;
        white-space: nowrap;

        @include mobile {
          padding-bottom: 2rem;
        }
      }
      > p {
        margin-left: 4rem;
        font-size: 1.4rem;
        line-height: 1.8;

        @include tablet {
          font-size: 1.3rem;
          > br {
            display: none;
          }
        }
        @include mobile {
          font-size: 1.2rem;
          margin-left: 0;
          > br {
            display: none;
          }
        }
      }
    }
  }
}
