@import '../../common.scss';

.container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: fit-content;

  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid lightgray;
    padding: 6px 0 5px;
    line-height: 1.4;

    > * {
      font-size: 1rem;

      @include tablet {
        font-size: 1.1rem;
      }
      @include mobile {
        font-size: 1.2rem;
      }
    }
    > .label {
      font-weight: bold;
      width: 6rem;
    }
    > .value {
      margin-right: 1rem;
    }
  }
}
