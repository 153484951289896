@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
      text-align: center;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;
      text-wrap: balance;

      @include tablet {
        > br {
          display: none;
        }
      }
      @include mobile {
        > br {
          display: none;
        }
      }
    }
    > .objects {
      @include absoluteFill;

      > img {
        position: absolute;
        width: 100%;

        &.left {
          max-width: 14rem;
          left: 0;
          top: 30%;

          @include mobile {
            top: 70%;
            max-width: 10rem;
          }
        }
        &.right {
          max-width: 18.75rem;
          right: 0;
          top: 50%;

          @include mobile {
            max-width: 12.25rem;
          }
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;

    @include mobile {
      height: 80%;
    }
  }
}
