@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  flex-direction: column;
  padding: 6rem 2rem;
  background-image: url('../../../assets/images/products/pure/s03/background.jpg');

  > .headline {
    font-size: 2.6rem;
    color: white;
    text-align: center;
    font-weight: bold;
  }
  > img {
    margin-top: 2rem;
    width: 100%;
    max-width: 36rem;
  }
  > .featuresTitle {
    margin-top: 4rem;
    font-size: 2.2rem;
    color: white;
    text-align: center;
  }
  > .features {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    max-width: 44rem;

    @include mobile {
      flex-direction: column;
    }
    > .feature {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.5rem;
      padding: 0.5rem 0.5rem 2rem;
      background-color: white;
      border-radius: 1rem;

      > img {
        width: 100%;
        border-radius: 0.7rem;
      }
      > .title {
        margin-top: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
        color: #167ee2;
      }
      > .desc {
        margin-top: 1rem;
        font-size: 1.2rem;
        line-height: 1.6;
        text-align: center;
      }
    }
  }
}
