@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  @include backgroundDefaultStyle;
  flex-direction: column;
  padding: 6rem 2rem;
  background-image: url('../../../assets/images/products/breezy/s03/background.png');

  > .headline {
    font-size: 2.6rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  > .desc {
    color: white;
    font-size: 1.4rem;
    max-width: 60rem;
    text-align: center;
    line-height: 1.6;
    text-wrap: balance;
  }
  > .box {
    margin-top: 3rem;
    background-color: white;
    width: 100%;
    max-width: 60rem;
    border-radius: 1rem;
    padding: 4rem 6rem;
    box-sizing: border-box;

    > .feature {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: 2.4rem;
      border-bottom: 1px solid rgba(#1ea0a0, 0.5);

      @include mobile {
        flex-direction: column;
        text-align: center;
      }
      &:not(:first-child) {
        padding-top: 3rem;
      }
      > .imageWrapper > img {
        width: 8rem;
      }
      > .wrapper {
        flex: 1;
        margin-left: 2.4rem;

        @include mobile {
          margin-left: initial;
        }
        > .title {
          font-size: 1.8rem;
          font-weight: bold;
          margin-bottom: 1rem;

          @include mobile {
            margin-top: 1rem;
          }
        }
        > .label {
          margin-top: 1rem;
          background-color: #1ea0a0;
          color: white;
          font-size: 1.2rem;
          display: inline-block;
          padding: 0 6px 4px;
          border-radius: 4px;
        }
        > .desc {
          margin-top: 0.5rem;
          font-size: 1.2rem;
          font-weight: 300;
          line-height: 1.6;
          text-wrap: balance;
        }
      }
    }
  }
}
