@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  background-color: $face-color;
  padding-bottom: 8rem;

  > .limiter {
    @include limiter;

    > .head {
      @include flexCenterStyle;
      border-top: 4px solid $accent-color;
      background-color: white;

      @include mobile {
        flex-wrap: wrap;
        margin-bottom: 4rem;
      }
      > li {
        width: 100%;
        font-size: 1.4rem;
        text-align: center;
        padding: 1.25rem 0;
        line-height: 1;
        cursor: pointer;

        &.active {
          font-weight: bold;
          color: $accent-color;
          background-color: #f0ede7;
        }
        @include mobile {
          width: 33.33334%;
        }
      }
    }
    > .body {
      border-top: 1px solid rgba(65, 54, 40, 0.3);

      > li {
        display: flex;
        flex-direction: column;

        &.active {
          > .title {
            &::after {
              content: '-';
            }
          }
          > .content {
            display: block;
          }
        }
        > .title {
          display: flex;
          cursor: pointer;
          font-size: 1.6rem;
          font-weight: bold;
          line-height: 1.5;
          padding: 1.25rem 1.5rem 1.25rem 1rem;
          border-bottom: 1px solid rgba(65, 54, 40, 0.3);
          position: relative;

          @include mobile {
            font-size: 1.25rem;
          }
          &::after {
            content: '+';
            color: $accent-color;
            position: absolute;
            right: 0;
            top: 1.25rem;
          }
        }
        > .content {
          display: none;
          background-color: rgba(195, 181, 155, 0.1);
          padding: 2rem;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(65, 54, 40, 0.3);

          > p {
            font-size: 1.2rem;
            line-height: 1.8;
            font-weight: lighter;
          }
        }
      }
    }
  }
}
