@import '../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  padding-bottom: 8rem;
  background-color: $face-color;

  > .limiter {
    @include limiter;
    @include flexCenterStyle;
    flex-direction: column;
    background-color: $accent-color;
    padding: 4.875rem 10rem;
    text-align: center;
    box-sizing: border-box;

    @include mobile {
      padding: 3rem 2rem;
    }
    > h3 {
      color: white;
      font-size: 2.6rem;
      font-weight: bold;
      line-height: 1.5;

      @include mobile {
        font-size: 2.25rem;
      }
    }
    > p {
      margin-top: 2.4rem;
      color: white;
      font-size: 1.6rem;
      line-height: 1.8;
    }
  }
}
