@import '../../common.scss';

.container {
  margin: -4.4rem auto 0;
  width: 100%;
  max-width: 100rem;

  @include mobile {
    display: none;
  }
  .tabs {
    display: flex;
    justify-content: space-around;
    margin: 0 1rem;
    padding: 0 2rem;
    overflow: scroll;
    background-color: rgba($face-color, 0.85);
    scrollbar-width: none; // Firefox 스크롤바 숨김
    -ms-overflow-style: none; // IE and Edge 스크롤바 숨김

    &::-webkit-scrollbar {
      display: none; // Chrome, Safari 스크롤바 숨김
    }

    > li {
      @include flexCenterStyle;

      > a {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        color: $accent-color;
        font-size: 1.4rem;
        font-weight: bold;
        height: 4.4rem;
        white-space: nowrap;
        line-height: 1;
        letter-spacing: -1px;
      }
      &.active {
        > a {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 1rem;
            left: 1rem;
            bottom: 0;
            height: 5px;
            background-color: $accent-color;
          }
        }
      }
    }
  }
}
