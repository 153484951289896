@import '../../common.scss';

.container {
  @include flexCenterStyle;
  padding: 6rem 0;
  border-top: 1px solid #ddd;

  > .limiter {
    @include limiter;

    > .flex {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      > .customer {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: calc(100% - 16rem);

        @include tablet {
          width: calc(100% - 13rem);
        }
        @include mobile {
          order: 3;
          width: 100%;
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
        > li {
          &:first-child {
            padding-right: 6rem;

            @include tablet {
              padding-right: 2rem;
            }
            @include mobile {
              padding: 0;
              margin-bottom: 2rem;
            }
          }
          > .title {
            color: $tint-color-1;
            font-weight: bold;
            font-size: 1.4rem;
          }
          > .tel {
            white-space: nowrap;
            font-size: 2.6rem;
            font-weight: bold;
            line-height: 1.5;
            color: $accent-color;
          }
          > .time {
            font-weight: bold;
            font-size: 1.2rem;
            line-height: 1.4;
          }
        }
      }
      > .familySite {
        @include dropDownIcon;
        padding: 1rem;
        background-color: white;
        box-sizing: border-box;
        font-size: 1rem;
        color: #666;
        border: 1px solid #999;
        width: 100%;
        max-width: 16rem;

        @include tablet {
          max-width: 13rem;
        }
        @include mobile {
          order: 2;
          width: 100%;
          max-width: 15rem;
          margin-left: 3rem;
        }
      }
      > .logo {
        margin-top: 5rem;
        width: 12rem;

        @include tablet {
          width: 10rem;
        }
        @include mobile {
          order: 1;
          margin-top: 0;
          width: calc(100% - 18rem);
          margin-bottom: 3rem;
        }
        > img {
          width: 100%;
          max-width: 9rem;

          @include tablet {
            max-width: 6rem;
          }
          @include mobile {
            max-width: 10rem;
          }
        }
      }
      > .info {
        width: calc(100% - 28rem);
        margin-top: 5rem;
        padding-right: 5rem;
        box-sizing: border-box;

        @include tablet {
          width: calc(100% - 23rem);
        }
        @include mobile {
          order: 5;
          width: 100%;
          margin-top: 3rem;
          padding: 0;
        }
        > ul {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          @include mobile {
            justify-content: center;
          }
          &.top {
            flex-wrap: wrap;

            @include mobile {
              display: flex;
              flex-direction: column;
            }
            > li {
              &:first-child {
                > a {
                  color: $accent-color;
                  font-weight: bold;
                }
              }
              > a {
                display: block;
                padding: 0 2rem 1rem 0;
                color: #666;
                font-size: 1.2rem;
                white-space: nowrap;

                @include mobile {
                  padding: 0 0 1rem;
                }
              }
            }
          }
          &.bottom {
            flex-wrap: wrap;

            @include mobile {
              letter-spacing: -1px;
              margin-top: 1rem;
            }
            > li {
              margin: 0 0.5rem 0.5rem 0;
              color: #666;
              font-size: 1.1rem;

              @include mobile {
                white-space: nowrap;
              }
            }
          }
        }
      }
      > .sns {
        width: 100%;
        max-width: 12rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;

        @include tablet {
          max-width: 13rem;
        }
        @include mobile {
          order: 4;
          max-width: 100%;
          margin-top: 3rem;
          justify-content: center;
        }
        > li {
          &:not(:first-child) {
            margin-left: 1.6rem;
          }
          > a {
            @include flexCenterStyle;

            > img {
              width: auto;
              max-height: 2.2rem;
            }
          }
        }
      }
    }
  }
}
