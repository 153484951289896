@import '../../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  padding: 6rem 0;
  background-color: $face-color;

  > .limiter {
    @include flexCenterStyle;
    @include limiter;
    flex-direction: column;

    > .title {
      @include sectionTitle;
      padding-bottom: 2.8rem;
      text-align: center;
      text-wrap: balance;
    }
    > p {
      font-size: 1.6rem;
      line-height: 1.8;
      text-align: center;
      margin-bottom: 5.5rem;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
    > .list {
      width: 100%;
      max-width: 80rem;

      .item {
        @include flexCenterStyle;
        flex-direction: column;
        height: 100%;

        > .image {
          @include backgroundDefaultStyle;
          @include flexCenterStyle;
          width: 100%;
          min-height: 16rem;

          > p {
            color: white;
            font-size: 1.6rem;
          }
        }
        > .text {
          @include flexCenterStyle;
          background-color: white;
          height: 12rem;
          width: 100%;

          > p {
            font-size: 1.4rem;
            text-align: center;
            line-height: 1.8;
            padding: 0 2rem;
            box-sizing: border-box;
          }
        }
      }
    }
    > .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 3rem;

      > div {
        display: flex;
        align-items: center;

        > span {
          display: block;
          width: 12px;
          height: 12px;
          border-right: 50%;
          background-color: $accent-color;
          opacity: 0.3;

          &.bulletsActive {
            opacity: 1;
          }
        }
      }
    }
  }
}
