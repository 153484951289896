@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  @include mobile {
    height: 50rem;
  }
  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      padding-top: 6rem;
      text-wrap: balance;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      text-align: center;
      line-height: 1.8;
      text-wrap: balance;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
  > .objects {
    @include absoluteFill;

    > img {
      position: absolute;
      width: 100%;

      &.leaf {
        &.left {
          max-width: 28rem;
          left: -4rem;
          top: 4rem;

          @include tablet {
            max-width: 16rem;
          }
          @include mobile {
            visibility: hidden;
          }
        }
        &.right {
          max-width: 32rem;
          right: -4rem;
          bottom: 4rem;

          @include tablet {
            max-width: 16rem;
          }
          @include mobile {
            visibility: hidden;
          }
        }
      }
      &.bubble {
        &.left {
          max-width: 8rem;
          top: 40%;
          left: 24%;

          @include mobile {
            visibility: hidden;
          }
        }
        &.right {
          max-width: 20rem;
          bottom: 10%;
          right: 20%;

          @include mobile {
            visibility: hidden;
          }
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @include tablet {
      height: 80%;
    }
    @include mobile {
      height: 64%;
    }
  }
}
