@import '../../../common.scss';

.container {
  > .section {
    @include flexCenterStyle;
    flex-direction: column;
    padding: 6rem 0;
    text-align: center;
    background-color: #eaf4ff;

    > .limiter {
      @include limiter;

      > h3 {
        @include sectionTitle;
      }
      > p {
        margin-top: 2rem;
        font-size: 1.6rem;
      }
      > img {
        margin-top: 3rem;
        width: 100%;
        max-width: 40rem;
      }
    }
  }
}
