@import '../../../common.scss';

.container {
  @include backgroundDefaultStyle;
  @include flexCenterStyle;
  height: 64rem;
  position: relative;
  overflow: hidden;

  @include mobile {
    height: 50rem;
  }
  > .limiter {
    @include limiter;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 100%;

    > .title {
      @include sectionTitle;
      color: white;
      padding-top: 6rem;
    }
    > .text {
      padding-top: 1.4rem;
      font-size: 1.6rem;
      color: white;
      text-align: center;
      line-height: 1.8;
      text-wrap: balance;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
  }
  > .objects {
    @include absoluteFill;

    > img {
      position: absolute;
      width: 100%;

      &.left {
        max-width: 20rem;
        left: 0;
        top: 0;

        @include tablet {
          max-width: 15rem;
        }
        @include mobile {
          max-width: 8rem;
        }
      }
      &.right {
        max-width: 31.25rem;
        right: -15rem;
        top: 40%;
      }
      &.bubbleLeft {
        max-width: 10rem;
        top: 60%;
        left: 15%;
      }
      &.bubbleRight {
        max-width: 12.5rem;
        top: 20%;
        right: 15%;

        @include mobile {
          max-width: 10rem;
          top: 50%;
        }
      }
    }
  }
  > .product {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);

    @include tablet {
      height: 80%;
    }
    @include mobile {
      height: 73%;
    }
  }
}
