@import '../../common.scss';

.container {
  @include flexCenterStyle;
  flex-direction: column;
  padding-bottom: 6rem;
  background-color: $face-color;

  > .logo {
    background-color: white;
    padding: 1.8rem 0;
    text-align: center;
    width: 100%;

    > img {
      width: 100%;
      max-width: 13.75rem;
    }
  }
  > .limiter {
    @include limiter;

    > h3 {
      margin-top: 4rem;
      font-size: 2.6rem;
      color: $accent-color;
      font-weight: bold;
      text-align: center;
    }
    > p {
      margin-top: 4rem;
      font-size: 1.6rem;
      line-height: 1.8;
      text-align: center;

      @include mobile {
        > br {
          display: none;
        }
      }
    }
    > .list {
      margin-top: 5rem;
      display: flex;
      justify-content: center;

      @include tablet {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @include mobile {
        flex-direction: column;
      }
      > li {
        width: 100%;
        min-height: 24rem;
        background-color: $accent-color;
        margin-right: 1rem;
        margin-bottom: 1rem;

        @include desktop {
          &:last-child {
            margin-right: 0;
          }
        }
        @include tablet {
          width: calc(50% - 0.5rem);

          &:nth-child(even) {
            margin-right: 0;
          }
        }
        @include mobile {
          margin-right: 0;
          min-height: initial;
        }
        > a {
          width: 100%;
          height: 100%;
          padding: 2rem;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          @include mobile {
            flex-direction: row;
          }
          > h4 {
            font-size: 1.8rem;
            font-weight: bold;
            line-height: 1.5;
            color: white;
          }
          > .icon {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            @include mobile {
              flex-direction: column;

              > img {
                &:first-child {
                  height: 5rem;
                  width: auto;
                  margin-bottom: 2rem;
                }
                &:last-child {
                  width: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
